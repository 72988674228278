import {
  BasePeerConnection,
  BasePeerConnectionOpts,
} from './BasePeerConnection';
import { TransceiverCache } from './TransceiverCache';
import {
  PeerType,
  PublishOption,
  TrackInfo,
  TrackType,
} from '../gen/video/sfu/models/models';
import { VideoSender } from '../gen/video/sfu/event/events';
import {
  findOptimalVideoLayers,
  OptimalVideoLayer,
  toSvcEncodings,
  toVideoLayers,
} from './videoLayers';
import { isSvcCodec } from './codecs';
import {
  isAudioTrackType,
  trackTypeToParticipantStreamKey,
} from './helpers/tracks';
import { extractMid } from './helpers/sdp';
import { withoutConcurrency } from '../helpers/concurrency';

export type PublisherConstructorOpts = BasePeerConnectionOpts & {
  publishOptions: PublishOption[];
};

/**
 * The `Publisher` is responsible for publishing/unpublishing media streams to/from the SFU
 *
 * @internal
 */
export class Publisher extends BasePeerConnection {
  private readonly transceiverCache = new TransceiverCache();
  private readonly knownTrackIds = new Set<string>();

  private readonly unsubscribeOnIceRestart: () => void;
  private readonly unsubscribeChangePublishQuality: () => void;
  private readonly unsubscribeChangePublishOptions: () => void;

  private publishOptions: PublishOption[];

  /**
   * Constructs a new `Publisher` instance.
   */
  constructor({ publishOptions, ...baseOptions }: PublisherConstructorOpts) {
    super(PeerType.PUBLISHER_UNSPECIFIED, baseOptions);
    this.publishOptions = publishOptions;
    this.pc.addEventListener('negotiationneeded', this.onNegotiationNeeded);

    this.unsubscribeOnIceRestart = this.dispatcher.on(
      'iceRestart',
      (iceRestart) => {
        if (iceRestart.peerType !== PeerType.PUBLISHER_UNSPECIFIED) return;
        this.restartIce().catch((err) => {
          this.logger('warn', `ICERestart failed`, err);
          this.onUnrecoverableError?.();
        });
      },
    );

    this.unsubscribeChangePublishQuality = this.dispatcher.on(
      'changePublishQuality',
      ({ videoSenders }) => {
        withoutConcurrency('publisher.changePublishQuality', async () => {
          for (const videoSender of videoSenders) {
            await this.changePublishQuality(videoSender);
          }
        }).catch((err) => {
          this.logger('warn', 'Failed to change publish quality', err);
        });
      },
    );

    this.unsubscribeChangePublishOptions = this.dispatcher.on(
      'changePublishOptions',
      (event) => {
        withoutConcurrency('publisher.changePublishOptions', async () => {
          this.publishOptions = event.publishOptions;
          return this.syncPublishOptions();
        }).catch((err) => {
          this.logger('warn', 'Failed to change publish options', err);
        });
      },
    );
  }

  /**
   * Closes the publisher PeerConnection and cleans up the resources.
   */
  close = ({ stopTracks }: { stopTracks: boolean }) => {
    if (stopTracks) {
      this.stopPublishing();
    }

    this.dispose();
  };

  /**
   * Detaches the event handlers from the `RTCPeerConnection`.
   * This is useful when we want to replace the `RTCPeerConnection`
   * instance with a new one (in case of migration).
   */
  detachEventHandlers() {
    this.unsubscribeOnIceRestart();
    this.unsubscribeChangePublishQuality();
    this.unsubscribeChangePublishOptions();

    super.detachEventHandlers();
    this.pc.removeEventListener('negotiationneeded', this.onNegotiationNeeded);
  }

  /**
   * Starts publishing the given track of the given media stream.
   *
   * Consecutive calls to this method will replace the stream.
   * The previous stream will be stopped.
   *
   * @param mediaStream the media stream to publish.
   * @param track the track to publish.
   * @param trackType the track type to publish.
   */
  publishStream = async (
    mediaStream: MediaStream,
    track: MediaStreamTrack,
    trackType: TrackType,
  ) => {
    if (track.readyState === 'ended') {
      throw new Error(`Can't publish a track that has ended already.`);
    }

    // enable the track if it is disabled
    if (!track.enabled) track.enabled = true;

    if (!this.knownTrackIds.has(track.id)) {
      // listen for 'ended' event on the track as it might be ended abruptly
      // by an external factors such as permission revokes, a disconnected device, etc.
      // keep in mind that `track.stop()` doesn't trigger this event.
      const handleTrackEnded = () => {
        this.logger('info', `Track ${TrackType[trackType]} has ended abruptly`);
        track.removeEventListener('ended', handleTrackEnded);
        this.notifyTrackMuteStateChanged(mediaStream, trackType, true).catch(
          (err) => this.logger('warn', `Couldn't notify track mute state`, err),
        );
      };
      track.addEventListener('ended', handleTrackEnded);

      // we now publish clones, hence we need to keep track of the original track ids
      // to avoid assigning the same event listener multiple times
      this.knownTrackIds.add(track.id);
    }

    for (const publishOption of this.publishOptions) {
      if (publishOption.trackType !== trackType) continue;

      // create a clone of the track as otherwise the same trackId will
      // appear in the SDP in multiple transceivers
      const trackToPublish = track.clone();

      const transceiver = this.transceiverCache.get(publishOption);
      if (!transceiver) {
        this.addTransceiver(trackToPublish, publishOption);
      } else {
        await this.updateTransceiver(transceiver, trackToPublish);
      }
    }

    await this.notifyTrackMuteStateChanged(mediaStream, trackType, false);
  };

  /**
   * Adds a new transceiver to the peer connection.
   * This needs to be called when a new track kind is added to the peer connection.
   * In other cases, use `updateTransceiver` method.
   */
  private addTransceiver = (
    track: MediaStreamTrack,
    publishOption: PublishOption,
  ) => {
    const videoEncodings = this.computeLayers(track, publishOption);
    const sendEncodings = isSvcCodec(publishOption.codec?.name)
      ? toSvcEncodings(videoEncodings)
      : videoEncodings;
    const transceiver = this.pc.addTransceiver(track, {
      direction: 'sendonly',
      sendEncodings,
    });

    const trackType = publishOption.trackType;
    this.logger('debug', `Added ${TrackType[trackType]} transceiver`);
    this.transceiverCache.add(publishOption, transceiver);
  };

  /**
   * Updates the given transceiver with the new track.
   * Stops the previous track and replaces it with the new one.
   */
  private updateTransceiver = async (
    transceiver: RTCRtpTransceiver,
    track: MediaStreamTrack,
  ) => {
    const previousTrack = transceiver.sender.track;
    // don't stop the track if we are re-publishing the same track
    if (previousTrack && previousTrack !== track) {
      previousTrack.stop();
    }
    await transceiver.sender.replaceTrack(track);
  };

  /**
   * Switches the codec of the given track type.
   */
  private syncPublishOptions = async () => {
    // enable publishing with new options -> [av1, vp9]
    for (const publishOption of this.publishOptions) {
      const { trackType } = publishOption;
      if (!this.isPublishing(trackType)) continue;
      if (this.transceiverCache.has(publishOption)) continue;

      const item = this.transceiverCache.find(
        (i) =>
          !!i.transceiver.sender.track &&
          i.publishOption.trackType === trackType,
      );
      if (!item || !item.transceiver) continue;

      // take the track from the existing transceiver for the same track type,
      // clone it and publish it with the new publish options
      const track = item.transceiver.sender.track!.clone();
      this.addTransceiver(track, publishOption);
    }

    // stop publishing with options not required anymore -> [vp9]
    for (const item of this.transceiverCache.items()) {
      const { publishOption, transceiver } = item;
      const hasPublishOption = this.publishOptions.some(
        (option) =>
          option.id === publishOption.id &&
          option.trackType === publishOption.trackType,
      );
      if (hasPublishOption) continue;
      // it is safe to stop the track here, it is a clone
      transceiver.sender.track?.stop();
      await transceiver.sender.replaceTrack(null);
    }
  };

  /**
   * Stops publishing the given track type to the SFU, if it is currently being published.
   * Underlying track will be stopped and removed from the publisher.
   * @param trackType the track type to unpublish.
   * @param stopTrack specifies whether track should be stopped or just disabled
   */
  unpublishStream = async (trackType: TrackType, stopTrack: boolean) => {
    for (const option of this.publishOptions) {
      if (option.trackType !== trackType) continue;

      const transceiver = this.transceiverCache.get(option);
      const track = transceiver?.sender.track;
      if (!track) continue;

      if (stopTrack && track.readyState === 'live') {
        track.stop();
      } else if (track.enabled) {
        track.enabled = false;
      }
    }

    if (this.state.localParticipant?.publishedTracks.includes(trackType)) {
      await this.notifyTrackMuteStateChanged(undefined, trackType, true);
    }
  };

  /**
   * Returns true if the given track type is currently being published to the SFU.
   *
   * @param trackType the track type to check.
   */
  isPublishing = (trackType: TrackType): boolean => {
    for (const item of this.transceiverCache.items()) {
      if (item.publishOption.trackType !== trackType) continue;

      const track = item.transceiver?.sender.track;
      if (!track) continue;

      if (track.readyState === 'live' && track.enabled) return true;
    }
    return false;
  };

  /**
   * Maps the given track ID to the corresponding track type.
   */
  getTrackType = (trackId: string): TrackType | undefined => {
    for (const transceiverId of this.transceiverCache.items()) {
      const { publishOption, transceiver } = transceiverId;
      if (transceiver.sender.track?.id === trackId) {
        return publishOption.trackType;
      }
    }
    return undefined;
  };

  // FIXME move to InputMediaDeviceManager
  private notifyTrackMuteStateChanged = async (
    mediaStream: MediaStream | undefined,
    trackType: TrackType,
    isMuted: boolean,
  ) => {
    await this.sfuClient.updateMuteState(trackType, isMuted);

    const audioOrVideoOrScreenShareStream =
      trackTypeToParticipantStreamKey(trackType);
    if (!audioOrVideoOrScreenShareStream) return;
    if (isMuted) {
      this.state.updateParticipant(this.sfuClient.sessionId, (p) => ({
        publishedTracks: p.publishedTracks.filter((t) => t !== trackType),
        [audioOrVideoOrScreenShareStream]: undefined,
      }));
    } else {
      this.state.updateParticipant(this.sfuClient.sessionId, (p) => {
        return {
          publishedTracks: p.publishedTracks.includes(trackType)
            ? p.publishedTracks
            : [...p.publishedTracks, trackType],
          [audioOrVideoOrScreenShareStream]: mediaStream,
        };
      });
    }
  };

  /**
   * Stops publishing all tracks and stop all tracks.
   */
  private stopPublishing = () => {
    this.logger('debug', 'Stopping publishing all tracks');
    this.pc.getSenders().forEach((s) => {
      s.track?.stop();
      if (this.pc.signalingState !== 'closed') {
        this.pc.removeTrack(s);
      }
    });
  };

  private changePublishQuality = async (videoSender: VideoSender) => {
    const { trackType, layers, publishOptionId } = videoSender;
    const enabledLayers = layers.filter((l) => l.active);
    this.logger(
      'info',
      'Update publish quality, requested layers by SFU:',
      enabledLayers,
    );

    const sender = this.transceiverCache.getWith(
      trackType,
      publishOptionId,
    )?.sender;
    if (!sender) {
      this.logger('warn', 'Update publish quality, no video sender found.');
      return;
    }

    const params = sender.getParameters();
    if (params.encodings.length === 0) {
      this.logger(
        'warn',
        'Update publish quality, No suitable video encoding quality found',
      );
      return;
    }

    const [codecInUse] = params.codecs;
    const usesSvcCodec = codecInUse && isSvcCodec(codecInUse.mimeType);

    let changed = false;
    for (const encoder of params.encodings) {
      const layer = usesSvcCodec
        ? // for SVC, we only have one layer (q) and often rid is omitted
          enabledLayers[0]
        : // for non-SVC, we need to find the layer by rid (simulcast)
          enabledLayers.find((l) => l.name === encoder.rid) ??
          (params.encodings.length === 1 ? enabledLayers[0] : undefined);

      // flip 'active' flag only when necessary
      const shouldActivate = !!layer?.active;
      if (shouldActivate !== encoder.active) {
        encoder.active = shouldActivate;
        changed = true;
      }

      // skip the rest of the settings if the layer is disabled or not found
      if (!layer) continue;

      const {
        maxFramerate,
        scaleResolutionDownBy,
        maxBitrate,
        scalabilityMode,
      } = layer;
      if (
        scaleResolutionDownBy >= 1 &&
        scaleResolutionDownBy !== encoder.scaleResolutionDownBy
      ) {
        encoder.scaleResolutionDownBy = scaleResolutionDownBy;
        changed = true;
      }
      if (maxBitrate > 0 && maxBitrate !== encoder.maxBitrate) {
        encoder.maxBitrate = maxBitrate;
        changed = true;
      }
      if (maxFramerate > 0 && maxFramerate !== encoder.maxFramerate) {
        encoder.maxFramerate = maxFramerate;
        changed = true;
      }
      // @ts-expect-error scalabilityMode is not in the typedefs yet
      if (scalabilityMode && scalabilityMode !== encoder.scalabilityMode) {
        // @ts-expect-error scalabilityMode is not in the typedefs yet
        encoder.scalabilityMode = scalabilityMode;
        changed = true;
      }
    }

    const activeLayers = params.encodings.filter((e) => e.active);
    if (!changed) {
      this.logger('info', `Update publish quality, no change:`, activeLayers);
      return;
    }

    await sender.setParameters(params);
    this.logger('info', `Update publish quality, enabled rids:`, activeLayers);
  };

  /**
   * Restarts the ICE connection and renegotiates with the SFU.
   */
  restartIce = async () => {
    this.logger('debug', 'Restarting ICE connection');
    const signalingState = this.pc.signalingState;
    if (this.isIceRestarting || signalingState === 'have-local-offer') {
      this.logger('debug', 'ICE restart is already in progress');
      return;
    }
    await this.negotiate({ iceRestart: true });
  };

  private onNegotiationNeeded = () => {
    withoutConcurrency('publisher.negotiate', () => this.negotiate()).catch(
      (err) => {
        this.logger('error', `Negotiation failed.`, err);
        this.onUnrecoverableError?.();
      },
    );
  };

  /**
   * Initiates a new offer/answer exchange with the currently connected SFU.
   *
   * @param options the optional offer options to use.
   */
  private negotiate = async (options?: RTCOfferOptions) => {
    const offer = await this.pc.createOffer(options);
    const trackInfos = this.getAnnouncedTracks(offer.sdp);
    if (trackInfos.length === 0) {
      throw new Error(`Can't negotiate without announcing any tracks`);
    }

    try {
      this.isIceRestarting = options?.iceRestart ?? false;
      await this.pc.setLocalDescription(offer);

      const { response } = await this.sfuClient.setPublisher({
        sdp: offer.sdp || '',
        tracks: trackInfos,
      });

      if (response.error) throw new Error(response.error.message);
      await this.pc.setRemoteDescription({ type: 'answer', sdp: response.sdp });
    } finally {
      this.isIceRestarting = false;
    }

    this.sfuClient.iceTrickleBuffer.publisherCandidates.subscribe(
      async (candidate) => {
        try {
          const iceCandidate = JSON.parse(candidate.iceCandidate);
          await this.pc.addIceCandidate(iceCandidate);
        } catch (e) {
          this.logger('warn', `ICE candidate error`, e, candidate);
        }
      },
    );
  };

  /**
   * Returns a list of tracks that are currently being published.
   *
   * @internal
   * @param sdp an optional SDP to extract the `mid` from.
   */
  getAnnouncedTracks = (sdp?: string): TrackInfo[] => {
    sdp = sdp || this.pc.localDescription?.sdp;
    const trackInfos: TrackInfo[] = [];
    for (const transceiverId of this.transceiverCache.items()) {
      const { publishOption, transceiver } = transceiverId;
      const track = transceiver.sender.track;
      if (!track) continue;

      const isTrackLive = track.readyState === 'live';
      const layers = isTrackLive
        ? this.computeLayers(track, publishOption)
        : this.transceiverCache.getLayers(publishOption);
      this.transceiverCache.setLayers(publishOption, layers);

      const isAudioTrack = isAudioTrackType(publishOption.trackType);
      const isStereo = isAudioTrack && track.getSettings().channelCount === 2;
      const transceiverIndex = this.transceiverCache.indexOf(transceiver);
      const mid = extractMid(transceiver, transceiverIndex, sdp);

      const audioSettings = this.state.settings?.audio;
      trackInfos.push({
        trackId: track.id,
        layers: toVideoLayers(layers),
        trackType: publishOption.trackType,
        mid,
        stereo: isStereo,
        dtx: isAudioTrack && !!audioSettings?.opus_dtx_enabled,
        red: isAudioTrack && !!audioSettings?.redundant_coding_enabled,
        muted: !isTrackLive,
      });
    }
    return trackInfos;
  };

  private computeLayers = (
    track: MediaStreamTrack,
    publishOption: PublishOption,
  ): OptimalVideoLayer[] | undefined => {
    if (isAudioTrackType(publishOption.trackType)) return;
    return findOptimalVideoLayers(track, publishOption);
  };
}
